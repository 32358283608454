import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import CreditCard from './pages/CreditCard';

function App() {
  return (
    <div>
      <CreditCard />
    </div>
  );
}

export default App;
